import * as React from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import Typography from '@mui/joy/Typography';

export interface AlertModalProps {
    open: boolean;
    title: string;
    description: string;
    dismiss: string;
    confirm: string;
    onClose: () => void;
    onConfirm: () => void;
}

export default function AlertModal(props: AlertModalProps) {
    return (
        <Modal open={props.open} onClose={() => props.onClose()}>
            <ModalDialog
                variant="outlined"
                role="alertdialog"
                aria-labelledby="alert-dialog-modal-title"
                aria-describedby="alert-dialog-modal-description"
            >
                <Typography
                    id="alert-dialog-modal-title"
                    component="h2"
                    startDecorator={<WarningRoundedIcon />}
                >
                    {props.title}
                </Typography>
                <Divider />
                <Typography id="alert-dialog-modal-description" textColor="text.tertiary">
                    {props.description}
                </Typography>
                <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', pt: 2 }}>
                    <Button variant="plain" color="neutral" onClick={() => props.onClose()}>
                        {props.dismiss}
                    </Button>
                    <Button variant="solid" color="danger" onClick={() => props.onConfirm()}>
                        {props.confirm}
                    </Button>
                </Box>
            </ModalDialog>
        </Modal>
    );
}