import React, {useState} from 'react'

import ListItem from '@mui/joy/ListItem'
import ListItemContent from '@mui/joy/ListItemContent'
import Typography from '@mui/joy/Typography'
import Input from '@mui/joy/Input'
import Button from '@mui/joy/Button'
import FormControl from '@mui/joy/FormControl'
import FormLabel from '@mui/joy/FormLabel'
import Box from '@mui/joy/Box'
import {BannerMessage} from './types'
import IosSwitch from './IosSwitch'
import AlertModal from './AlertModal'
import {db} from '../services/firebase'
import {doc, setDoc} from 'firebase/firestore'
import ColorPicker from './ColorPicker'

interface BannerMessageListItemProps {
  bannerMessage: BannerMessage
  onDelete: () => void
}

function BannerMessageListItem({bannerMessage, onDelete}: BannerMessageListItemProps) {
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false)
  const [message, setMessage] = useState<string>(bannerMessage.message)
  const [active, setActive] = useState<boolean>(bannerMessage.active)
  const [backgroundColor, setBackgroundColor] = useState<string>(bannerMessage.backgroundColor)
  const [backgroundHoverColor, setBackgroundHoverColor] = useState<string>(bannerMessage.backgroundHoverColor)
  const [textColor, setTextColor] = useState<string>(bannerMessage.textColor)

  const [showTextColorPicker, setShowTextColorPicker] = useState<boolean>(false)
  const [showBackgroundColorPicker, setShowBackgroundColorPicker] = useState<boolean>(false)
  const [showBackgroundHoverColorPicker, setShowBackgroundHoverColorPicker] = useState<boolean>(false)

  const onCancel = () => {
    setMessage(bannerMessage.message)
    setActive(bannerMessage.active)
    setBackgroundColor(bannerMessage.backgroundColor)
    setBackgroundHoverColor(bannerMessage.backgroundHoverColor)
    setTextColor(bannerMessage.textColor)
    setIsDirty(false)
  }
  const handleMessageChange = (e: any) => {
    setMessage(e.target.value)
    setIsDirty(true)
  }

  const updateBannerMessage = async () => {
    try {
      // Set improved transcription and change status to 'improved'
      const messageRef = doc(db, 'bannerMessages', bannerMessage.id)

      const tempBannerMessage = {
        message: message,
        active: active,
        backgroundColor: backgroundColor,
        backgroundHoverColor: backgroundHoverColor,
        textColor: textColor,
      }

      await setDoc(messageRef, tempBannerMessage, {merge: true})
        .then(() => {})
        .catch((error) => {
          console.error('Error updating document: ', error)
          alert('Failed updating the banner! Please try again. Error: ' + error)
        })
    } catch (error) {
      console.log(error)
      alert('Failed updating the banner! Please try again. Error: ' + error)
    }
  }

  const handleDelete = () => {
    setShowConfirmDelete(true)
  }

  const onSave = () => {
    updateBannerMessage()
    console.log('Save clicked')
    setIsDirty(false)
  }
  const onActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActive(!active)
    setIsDirty(true)
  }

  const onBackgroundColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBackgroundColor(event.target.value)
    setIsDirty(true)
  }

  const onBackgroundHoverColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBackgroundHoverColor(event.target.value)
    setIsDirty(true)
  }

  const onTextColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextColor(event.target.value)
    setIsDirty(true)
  }

  return (
    <ListItem sx={{backgroundColor: active ? '#FFF4E1' : '#F0F0F0', borderRadius: '8px'}}>
      <AlertModal
        open={showConfirmDelete}
        title='Delete banner'
        description='Are you sure you want to delete this banner?'
        onConfirm={() => {
          onDelete()
          setShowConfirmDelete(false)
        }}
        onClose={() => setShowConfirmDelete(false)}
        dismiss='Cancel'
        confirm='Delete'
      />
      <ListItemContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
          width: '100%',
        }}
      >
        <Box sx={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginBottom: '8px'}}>
          <Typography level='title-lg'>{active ? 'Active' : 'Inactive'}</Typography>
          <IosSwitch
            onChange={onActiveChange}
            checked={active}
          />
        </Box>

        <FormControl sx={{marginBottom: '16px'}}>
          <FormLabel>Message</FormLabel>
          <Input
            value={message}
            onChange={handleMessageChange}
            placeholder='No message set'
          />
        </FormControl>

        <Box
          sx={{
            display: 'flex-wrap',
            flexDirection: {xs: 'col', sm: 'row', md: 'row', lg: 'row'},
            width: '100%',
            marginBottom: '16px',
          }}
        >
          <FormControl>
            <FormLabel>Background color</FormLabel>
            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <Input
                value={backgroundColor}
                onChange={onBackgroundColorChange}
                placeholder='Background color'
              />
              <Box
                sx={{
                  backgroundColor,
                  width: '35px',
                  height: '35px',
                }}
                marginRight={'1rem'}
                onClick={() => {
                  setShowBackgroundColorPicker(true)
                }}
              />
            </Box>
          </FormControl>

          {showBackgroundColorPicker && (
            <ColorPicker
              initialColor={backgroundColor}
              onCancel={(initialColor: string) => {
                setShowBackgroundColorPicker(false)
                setBackgroundColor(initialColor)
              }}
              onSelect={(color: string) => {
                setShowBackgroundColorPicker(false)
                setIsDirty(true)
                setBackgroundColor(color)
              }}
            />
          )}

          <FormControl>
            <FormLabel>Background hover</FormLabel>
            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <Input
                value={backgroundHoverColor}
                onChange={onBackgroundHoverColorChange}
                placeholder='Background hover color'
              />
              <Box
                sx={{
                  backgroundColor: backgroundHoverColor,
                  width: '35px',
                  height: '35px',
                }}
                marginRight={'1rem'}
                onClick={() => {
                  setShowBackgroundHoverColorPicker(true)
                }}
              />
            </Box>
          </FormControl>

          {showBackgroundHoverColorPicker && (
            <ColorPicker
              initialColor={backgroundHoverColor}
              onCancel={(initialColor: string) => {
                setShowBackgroundHoverColorPicker(false)
                setBackgroundHoverColor(initialColor)
              }}
              onSelect={(color: string) => {
                setShowBackgroundHoverColorPicker(false)
                setIsDirty(true)
                setBackgroundHoverColor(color)
              }}
            />
          )}

          <FormControl>
            <FormLabel>Text color</FormLabel>
            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <Input
                value={textColor}
                onChange={onTextColorChange}
                placeholder='Text color'
              />
              <Box
                sx={{
                  backgroundColor: textColor,
                  width: '35px',
                  height: '35px',
                }}
                marginRight={'1rem'}
                onClick={() => {
                  setShowTextColorPicker(true)
                }}
              />
            </Box>
          </FormControl>

          {showTextColorPicker && (
            <ColorPicker
              initialColor={textColor}
              onCancel={(initialColor: string) => {
                setShowTextColorPicker(false)
                setTextColor(initialColor)
              }}
              onSelect={(color: string) => {
                setShowTextColorPicker(false)
                setIsDirty(true)
                setTextColor(color)
              }}
            />
          )}
        </Box>

        <Typography
          level='title-lg'
          sx={{fontSize: '18px'}}
        >
          Preview
        </Typography>
        <Box
          sx={{
            textAlign: 'center',
            backgroundColor,
            '&:hover': {
              backgroundColor: backgroundHoverColor,
            },
            padding: '1rem',
            color: textColor,
          }}
        >
          {message}
        </Box>
        {isDirty && (
          <Box
            sx={{
              alignSelf: 'flex-end',
              width: '200px',
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '1rem',
            }}
          >
            <Button
              onClick={onCancel}
              variant='plain'
            >
              Cancel
            </Button>
            <Button onClick={onSave}>Save</Button>
          </Box>
        )}
        <Button
          onClick={handleDelete}
          variant='plain'
          color='danger'
          sx={{
            marginTop: '1rem',
            width: '200px',
            alignSelf: 'center',
          }}
        >
          Delete
        </Button>
      </ListItemContent>
    </ListItem>
  )
}

export default BannerMessageListItem
