import React, {useEffect} from 'react'

import {useNavigate, Outlet} from 'react-router-dom'

import Button from '@mui/joy/Button'
import Box from '@mui/joy/Box'

import {useAuth} from '../context/AuthContext'
import Header from '../components/Header'

import {collection, query, orderBy, getDocs, limit} from 'firebase/firestore'
import {db} from '../services/firebase'

export default function Main() {
  const {signout} = useAuth()
  const navigate = useNavigate()
  const [approved, setApproved] = React.useState(false)

  const tryReadingImprovementRequests = async () => {
    try {
      const improvementRequestsRef = collection(db, 'improvementRequests')
      const improvementRequestsQuery = query(improvementRequestsRef, orderBy('createdAt', 'desc'), limit(1))
      const q = query(improvementRequestsQuery)
      await getDocs(q)
      setApproved(true)
    } catch {
      setApproved(false)
      signout()
    }
  }

  useEffect(() => {
    try {
      tryReadingImprovementRequests()
    } catch {
      signout()
    }
  })

  if (!approved) {
    return null
  }

  return (
    <Box
      sx={{
        maxWidth: '960px',
        mx: 'auto',
      }}
    >
      <Header>
        <Button
          size='sm'
          onClick={async function () {
            await signout()
          }}
          variant='plain'
        >
          Logout
        </Button>

        <Box sx={{flexGrow: 1}}>
          <Button
            size='sm'
            onClick={async function () {
              navigate('/improvementRequests')
            }}
            variant='plain'
          >
            Improvement requests
          </Button>

          <Button
            size='sm'
            onClick={async function () {
              navigate('/bannerMessages')
            }}
            variant='plain'
          >
            Banners
          </Button>
        </Box>
      </Header>

      <Outlet />
    </Box>
  )
}
