import React, {useState, useEffect, useRef} from 'react'

import {
  collection,
  query,
  addDoc,
  deleteDoc,
  doc,
  startAfter,
  limit,
  onSnapshot,
  DocumentSnapshot,
} from 'firebase/firestore'

import {db} from '../services/firebase'

import Button from '@mui/joy/Button'
import Typography from '@mui/joy/Typography'
import List from '@mui/joy/List'
import Box from '@mui/joy/Box'

import CircularProgress from '@mui/material/CircularProgress'

import BannerMessageListItem from './BannerMessageListItem'

import {BannerMessage} from './types'

const PAGE_SIZE = 10

export default function BannerMessages() {
  const [loading, setLoading] = useState(false)

  const [bannerMessages, setBannerMessages] = useState<BannerMessage[]>([])
  const [lastDoc, setLastDoc] = useState<DocumentSnapshot | null>(null)
  const [hasMore, setHasMore] = useState<boolean>(false)

  const unsubscribeRef = useRef<(() => void) | null>(null)

  const fetchBanners = async (lastDocument: DocumentSnapshot | null = null) => {
    setLoading(true)

    const bannerMessagesRef = collection(db, 'bannerMessages')
    let bannerMessagesQuery = query(bannerMessagesRef, limit(PAGE_SIZE))

    if (lastDocument) {
      bannerMessagesQuery = query(bannerMessagesRef, startAfter(lastDocument), limit(PAGE_SIZE))
    }

    const unsubscribe = onSnapshot(
      bannerMessagesQuery,
      (snapshot) => {
        if (snapshot.empty) {
          setHasMore(false)
          setLoading(false)
          return
        }

        const newBannerMessages = snapshot.docs.map((doc) => ({id: doc.id, ...doc.data()} as BannerMessage))

        setBannerMessages((prevBannerMessages) => [
          ...prevBannerMessages,
          ...newBannerMessages.filter(
            (newMessage) => !prevBannerMessages.some((prevMessage) => prevMessage.id === newMessage.id)
          ),
        ])

        setHasMore(snapshot.docs.length === PAGE_SIZE)
        setLastDoc(snapshot.docs[snapshot.docs.length - 1])
        setLoading(false)
      },
      (error) => {
        setLoading(false)
        console.log(error)
      }
    )

    return unsubscribe
  }

  const onDelete = async (id: string) => {
    try {
      const messageRef = doc(db, 'bannerMessages', id)
      await deleteDoc(messageRef)
        .then(() => {
          setBannerMessages(bannerMessages.filter((message) => message.id !== id))
        })
        .catch((error) => {
          console.error('Error deleting document: ', error)
          alert('Failed deleting the banner message! Please try again. Error: ' + error)
        })
    } catch (error) {
      console.log(error)
      alert('Failed deleting note! Please try again. Error: ' + error)
    }
  }

  const onNewBannerMessage = async () => {
    try {
      const messageCollectionRef = collection(db, 'bannerMessages')
      const newMessage = {
        message: 'New banner message',
        active: false,
        backgroundColor: '#000000',
        backgroundHoverColor: '#000000',
        textColor: '#FFFFFF',
      }
      await addDoc(messageCollectionRef, newMessage)
        .then(() => {})
        .catch((error) => {
          console.error('Error adding document: ', error)
          alert('Error creating new banner message! Please try again. Error: ' + error)
        })
    } catch (error) {
      console.log(error)
      alert('Error creating new banner message! Please try again. Error: ' + error)
    }
  }

  useEffect(() => {
    fetchBanners().then((unsubscribe) => {
      unsubscribeRef.current = unsubscribe
    })

    return () => {
      unsubscribeRef.current?.()
      setBannerMessages([])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLoadMore = () => {
    if (!loading && lastDoc) {
      fetchBanners(lastDoc)
    }
  }

  return (
    <>
      <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', px: 3, marginTop: 2}}>
        <Typography level='h3'>Banners</Typography>
      </Box>
      <Box sx={{m: 1, mb: 0, textAlign: 'center', borderStyle: 'dotted', borderColor: 'gray'}}>
        <Button
          variant='plain'
          sx={{width: '100%', height: '100%'}}
          onClick={onNewBannerMessage}
        >
          New banner
        </Button>
      </Box>
      {loading ? (
        // Loading state
        <Box sx={{display: 'flex', justifyContent: 'center', p: 2}}>
          <CircularProgress />
        </Box>
      ) : bannerMessages.length === 0 ? (
        // No data state
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '60vh',
          }}
        >
          <Typography
            level='h4'
            textColor='#757575'
          >
            Jay! all done!
          </Typography>
        </Box>
      ) : (
        // Data loaded state
        <Box sx={{p: 2}}>
          <List>
            {bannerMessages.map((bannerMessage) => (
              <React.Fragment key={bannerMessage.id}>
                <BannerMessageListItem
                  bannerMessage={bannerMessage}
                  onDelete={() => {
                    onDelete(bannerMessage.id)
                  }}
                />
                <Box sx={{p: 1}} />
              </React.Fragment>
            ))}
          </List>
          <Box sx={{p: 1, textAlign: 'center'}}>
            {hasMore && (
              <Button
                variant='plain'
                onClick={handleLoadMore}
              >
                Load more
              </Button>
            )}
          </Box>
        </Box>
      )}
    </>
  )
}
