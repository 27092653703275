import React, {useState} from 'react'
import {HexColorPicker} from 'react-colorful'
import Button from '@mui/joy/Button'
import Box from '@mui/joy/Box'

interface ColorPickerProps {
  initialColor: string
  onSelect: (color: string) => void
  onCancel: (initialColor: string) => void
}

const ColorPicker: React.FC<ColorPickerProps> = ({initialColor, onSelect, onCancel}) => {
  const regretColor = initialColor
  const [color, setColor] = useState(initialColor)

  return (
    <Box
      display='flex'
      flexDirection='column'
    >
      <HexColorPicker
        color={color}
        onChange={setColor}
      />
      <Box sx={{display: 'flex'}}>
        <Button
          variant='plain'
          onClick={() => onCancel(regretColor)}
        >
          Cancel
        </Button>
        <Box
          sx={{
            backgroundColor: regretColor,
            width: '35px',
            height: '35px',
          }}
        ></Box>
        <Box
          sx={{
            backgroundColor: color,
            width: '35px',
            height: '35px',
          }}
        ></Box>
        <Button onClick={() => onSelect(color)}>Select</Button>
      </Box>
    </Box>
  )
}

export default ColorPicker
